.container {
  max-width: 90%;
  /* Use percentage for relative sizing */
  padding: 0 35px;
  /* Use percentage for relative padding */
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  /* Stack on mobile by default */
  gap: 20px;
  margin-top: 20px;
}

.leftSection,
.rightSection {
  flex: 1;
  /* Allow sections to grow and shrink as needed */
}

.title {
  color: white;
  font-family: 'Quarto', serif;
  font-weight: 900;
  font-size: 55px;
  /* Use viewport width for relative sizing */
  margin-bottom: 2vw;
  line-height: 80%;
  /* Avoid line height being too tight on smaller screens */
}

.helpParagraph {
  margin: 0;
  font-size: 3vw;
}

.magazineImage {
  width: 100%;
  /* Make image responsive */
  height: auto;
  /* Maintain aspect ratio */
}

.emailLink {
  color: #ffffff;
  text-decoration: none;
  font-size: 3vw;
}

.emailLink:hover,
.emailLink:focus {
  text-decoration: underline;
  color: #ffd700;
  outline: none;
  cursor: pointer;
}

.helpText {
  position: static;
  margin-top: 32px;
  padding-bottom: 75px;
}

p {
  font-size: 19px;
  line-height: 100%;
  margin: 19px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .title {
    font-size: 10vw;
    line-height: 9vw;
  }

  p {
    font-size: 4vw;
    line-height: 4vw;
  }
  .contentWrapper {
    flex-direction: column;
    /* Horizontal layout for tablets and up */
    gap: 20px;
  }

  .leftSection,
  .rightSection {
    width: auto;
    /* Reset width for larger devices */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    max-width: 85rem;
    /* Fixed max-width for large screens */
    padding: 0 4%;
    /* Larger padding on larger screens */
  }

  .title {
    font-size: 82px;
    line-height: 82%;
    margin-top: 0px;
  }

  p {
    font-size: 28px;
    line-height: 100%;
  }

  .helpParagraph {
    font-size: 18px;
    line-height: 100%;
  }

  .helpText {
    margin-top: 64px;
  }

  .leftSection {
    flex-basis: 50%;
    /* Adjust the width of left section */
  }

  .rightSection {
    flex-basis: 50%;
    /* Adjust the width of right section */
  }

  .magazineImage {
    width: 45vw;
  }

  .contentWrapper {
    flex-direction: row;
    /* Horizontal layout for tablets and up */
    gap: 100px;
  }
}

/* Assuming the Extra Large devices should start at 1500px and up */
@media (min-width: 1500px) {

  p {
    font-size: 2vw;
    line-height: 2vw;
  }

  .helpParagraph {
    font-size: 1.2vw;
  }

  .container {
    max-width: 160rem;
    /* Increase max-width for extra large screens */
    padding: 0 3%;
    /* Adjust padding */
  }

  .contentWrapper {
    justify-content: space-between;
    gap: 100px
    /* Space between items */
  }

  .magazineImage {
    max-width: 1400px;
  }
}
