.input {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 999px;
  padding: 16px 32px;
  width: 242px;
  height: 30px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 32px;
  margin-top: 22px;
  margin-bottom: 22px;
}

.errorMessage {
  color: red;
}

.flexContainer {
  display: flex;
  align-items: flex-start;
  gap: 36px;
}

.paragraph {
  margin: 0;
  display: flex;
  align-items: center;
}

.textArrowContainer {
  display: flex;
  align-items: center;
}

.arrow {
  margin-top: 10px;
}

.inputButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 0px;
  padding: 10px 32px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 24px;
  width: auto;
  align-self: start;
}

.modal {
  position: relative;
  z-index: 1000;
  background: #D2D2D2;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .modalParagraph {
    margin-top: 34px;
    margin-bottom: 0px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Small devices (smartphones, 200px and up) */
@media (min-width: 200px) {

  .modalParagraph {
    font-size: 16px;
  }

  .flexContainer {
    margin-top: 50px;
  }
  .arrow{
    width: 50px;
  }

  .flexContainer {
    gap: 21px;
  }

  .input {
    padding: 10px 25px;
    width: 150px;
    height: 20px;
    font-size: 19px;
  }

  .button {
    padding: 7px 12px;
    font-size: 19px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .input {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 999px;
    padding: 16px 32px;
    width: 242px;
    height: 30px;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 24px;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .flexContainer {
    display: flex;
    align-items: flex-start;
    gap: 36px;
  }

  .arrow {
    width: auto;
  }

  .paragraph {
    width: 70%;
  }

  .modalParagraph {
    font-size: 18px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .paragraph {
    width: auto;
  }

  .modalParagraph {
    font-size: 19px;
  }
}

/* Extra Large devices (large screens, 1500px and up) */
@media (min-width: 1500px) {
  .input {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 999px;
    padding: 2vw 3vw;
    width: 8vw;
    height: 2vh;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 2vw;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .modalParagraph {
    font-size: 1.5vw;
    margin-top: 5vh !important;
  }

  .closeButton {
    font-size: 1.5vw;
    top: 1vw;
    left: 1vw;
  }

  .modal {
    padding: 1vw;
  }

  .button {
    font-size: 2vw;
  }
}
