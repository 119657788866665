body {
  margin: 0;
  height: 100vh;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  color: black;
  background-color: #F2A900;
}

.App {
  height: 100%;
}

@media (min-width: 200px) {
  body {
    font-size: 19px;
    line-height: 100%;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 24px;
  }
}

@media (min-width: 1500px) {
  body {
    font-size: 28px;
  }
}
