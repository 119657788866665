.navbar {
  width: 100%;
}

.navbarContainer {
  max-width: 95%;
  margin: 0 auto;
}

/* Small devices (smartphones, 400px and up) */
@media (min-width: 200px) {

  .logo {
    width: 200px;
  }

  .navbarContainer {
    max-width: 120rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .logo {
    width: 300px;
  }

  .navbarContainer {
    max-width: 120rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .logo {
    width: 350px;
  }

  .navbarContainer {
    max-width: 160rem;
  }
}

/* Extra Large devices (large screens, 1500px and up) */
@media (min-width: 1500px) {

  .logo {
    width: 400px;
  }

  .navbarContainer {
    max-width: 160rem;
  }
}
