@font-face {
  font-family: 'Jost';
  src: url('./Jost-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Quarto';
  src: url('./Quarto-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
